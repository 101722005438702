/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GetterTree } from 'vuex'
import { TRootState } from '../types'
import { AppState } from './types'

export default {
  portfolioCo2Emissions: (state: AppState): number|null => {
    if (state.co2Emissions === null) {
      return null
    }
    return state.co2Emissions?.portfolio_result?.value ?? null
  },

  portfolioEnergyConsumption: (state: AppState): number|null => {
    if (state.energyConsumption === null) {
      return null
    }
    return state.energyConsumption?.portfolio_result?.value ?? null
  },

  portfolioCo2EmissionsUnit: (state: AppState, _getters, _rootState, rootGetters): string|null => {
    if (state.co2Emissions === null) {
      return null
    }
    const unit: string|null = state.co2Emissions?.units ?? null

    if (unit === null) {
      return null
    }

    const unitValue: any|null = rootGetters['labels/label']('units', unit)

    if (unitValue === null) {
      return null
    } else {
      return unitValue.symbol
    }
  },

  portfolioEnergyConsumptionUnit: (state: AppState, _getters, _rootState, rootGetters): string|null => {
    if (state.energyConsumption === null) {
      return null
    }
    const unit: string|null = state.energyConsumption?.units ?? null

    if (unit === null) {
      return null
    }

    const unitValue: any|null = rootGetters['labels/label']('units', unit)

    if (unitValue === null) {
      return null
    } else {
      return unitValue.symbol
    }
  }
} as GetterTree<AppState, TRootState>
