


























































































































































































































































































import Vue from 'vue'
import moment from 'moment'
import get from 'lodash.get'
import i18n from '@/plugins/i18n'

import Card from '@/components/ui/Card.vue'
import LineChart from '@/components/ui/Charts/LineChart.vue'
import MediaContent from '../Project/Media/EnergyConsumption/MediaContent.vue'
import HeatAndElectricityAggregated from './HeatAndElectricityAggregated.vue'
import AnnualDurationLines from './AnnualDurationLines.vue'

import { TObservationShort, TTimeseriesShort } from '@/services/aedifion/resources/project/responseTypes'
import { AxiosResponse } from 'axios'
import { IRepository } from '@/utils/types/repository'
import aedifionApiRepository from '@/services/aedifion'
import { TFlattenedMeterComponent, TDropDownMeters, FetchPDFReportPayload, YearlyComparisonObject } from '@/store/energy_management/types'
import { computeTotalConsumptionValue } from '@/utils/helpers/timeseries'
const Datapoint: IRepository = aedifionApiRepository.get('datapoints')
const Project: IRepository = aedifionApiRepository.get('projects')

export default Vue.extend({
  name: 'Energy',

  components: {
    Card,
    LineChart,
    MediaContent,
    HeatAndElectricityAggregated,
    AnnualDurationLines
  },

  data () {
    return {
      singleSelectMeterSeries: [] as Array<{ identifier: string; name: string; step: string; data: [number, number][] }>,
      selectedTimeSeries: [] as any,
      loadingSingleSelectMeterSeries: false as boolean,
      selectedMeters: [] as TFlattenedMeterComponent[],
      initialMeters: [] as { text: string; value: string}[],
      initialYear: [moment().year().toString()] as string[],
      metersTimeSeries: '' as string,
      yearsToTimeSeries: [moment().year().toString()] as string[],
      currentSelectedTimeSeries: [] as string[],
      multiselectMeterDatepickerMenu: false as boolean,
      meterSelectionDates: [
        moment().format('YYYY-MM-DD'),
        moment().subtract(1, 'month').format('YYYY-MM-DD')
      ] as string[],
      pollReportInterval: null as any|null,
      pendingPdfGeneration: false as boolean,
      timeSeriesMap: new Map() as Map<string, Map<string, any>>,
      showReportDialog: false as boolean,
      monthlyReport: false as boolean,
      selectedReportMonth: undefined as number|undefined,
      monthlyReportSelectionError: false as boolean
    }
  },

  computed: {
    loadingPDFReport (): boolean {
      return this.$store.state.energy_management.loadingPdfReport
    },

    loadingMeters (): boolean {
      return this.$store.getters['energy_management/isLoadingMeters']
    },

    loadingMetersLatestValues (): boolean {
      return this.$store.getters['energy_management/isLoadingMetersLatestTimeseries']
    },

    meters (): TFlattenedMeterComponent[] {
      return this.$store.getters['energy_management/getMeters']
    },

    monthlyReportSelection (): Array<{ text: string; value: number; disabled: boolean }> {
      return [
        {
          text: moment({ month: 0 }).locale(this.$i18n.locale).format('MMMM'),
          value: 0,
          disabled: moment({ month: 0 }).isAfter(moment())
        },
        {
          text: moment({ month: 1 }).locale(this.$i18n.locale).format('MMMM'),
          value: 1,
          disabled: moment({ month: 1 }).isAfter(moment())
        },
        {
          text: moment({ month: 2 }).locale(this.$i18n.locale).format('MMMM'),
          value: 2,
          disabled: moment({ month: 2 }).isAfter(moment())
        },
        {
          text: moment({ month: 3 }).locale(this.$i18n.locale).format('MMMM'),
          value: 3,
          disabled: moment({ month: 3 }).isAfter(moment())
        },
        {
          text: moment({ month: 4 }).locale(this.$i18n.locale).format('MMMM'),
          value: 4,
          disabled: moment({ month: 4 }).isAfter(moment())
        },
        {
          text: moment({ month: 5 }).locale(this.$i18n.locale).format('MMMM'),
          value: 5,
          disabled: moment({ month: 5 }).isAfter(moment())
        },
        {
          text: moment({ month: 6 }).locale(this.$i18n.locale).format('MMMM'),
          value: 6,
          disabled: moment({ month: 6 }).isAfter(moment())
        },
        {
          text: moment({ month: 7 }).locale(this.$i18n.locale).format('MMMM'),
          value: 7,
          disabled: moment({ month: 7 }).isAfter(moment())
        },
        {
          text: moment({ month: 8 }).locale(this.$i18n.locale).format('MMMM'),
          value: 8,
          disabled: moment({ month: 8 }).isAfter(moment())
        },
        {
          text: moment({ month: 9 }).locale(this.$i18n.locale).format('MMMM'),
          value: 9,
          disabled: moment({ month: 9 }).isAfter(moment())
        },
        {
          text: moment({ month: 10 }).locale(this.$i18n.locale).format('MMMM'),
          value: 10,
          disabled: moment({ month: 10 }).isAfter(moment())
        },
        {
          text: moment({ month: 11 }).locale(this.$i18n.locale).format('MMMM'),
          value: 11,
          disabled: moment({ month: 11 }).isAfter(moment())
        }
      ]
    },

    showsConsumption (): boolean {
      return this.$store.getters['energy_management/showConsumption']
    },

    headers (): any[] {
      return [
        {
          text: this.$t('energy_management.meters.table.meter_number') as string,
          value: 'identifier',
          width: '200px'
        },
        {
          text: this.$t('energy_management.meters.table.name') as string,
          value: 'name',
          width: '200px'
        },
        {
          text: this.$t('energy_management.meters.table.type') as string,
          value: 'type',
          width: '100px'
        },
        {
          text: 'Jan',
          value: 'monthly.jan',
          width: '100px'
        },
        {
          text: 'Feb',
          value: 'monthly.feb',
          width: '100px'
        },
        {
          text: 'Mar',
          value: 'monthly.mar',
          width: '100px'
        },
        {
          text: 'Apr',
          value: 'monthly.apr',
          width: '100px'
        },
        {
          text: 'May',
          value: 'monthly.may',
          width: '100px'
        },
        {
          text: 'Jun',
          value: 'monthly.jun',
          width: '100px'
        },
        {
          text: 'Jul',
          value: 'monthly.jul',
          width: '100px'
        },
        {
          text: 'Aug',
          value: 'monthly.aug',
          width: '100px'
        },
        {
          text: 'Sep',
          value: 'monthly.sep',
          width: '100px'
        },
        {
          text: 'Okt',
          value: 'monthly.okt',
          width: '100px'
        },
        {
          text: 'Nov',
          value: 'monthly.nov',
          width: '100px'
        },
        {
          text: 'Dec',
          value: 'monthly.dec',
          width: '100px'
        }
      ]
    },

    listOfYears (): string[] {
      const currentYear = new Date().getFullYear()
      const years = []
      let startYear = 2017

      while (startYear <= currentYear) {
        years.push(startYear++)
      }
      return years.map(item => item.toString()).reverse()
    },

    dropDownMeters (): TDropDownMeters[] {
      const result: TDropDownMeters[] = []
      this.meters.map((item: TFlattenedMeterComponent) => {
        result.push({ text: item.identifier, value: item.originalName })
      })
      return result
    },

    electricityColor (): string|null {
      return this.$store.getters['energy_consumption/getElectricityConsumptionColor']
    },

    heatColor (): string|null {
      return this.$store.getters['energy_consumption/getHeatConsumptionColor']
    },

    selectedMetersStartDate (): string {
      return moment(this.meterSelectionDates[0]).isBefore(moment(this.meterSelectionDates[1])) ? moment(this.meterSelectionDates[0]).startOf('day').toISOString(true) : moment(this.meterSelectionDates[1]).startOf('day').toISOString()
    },

    selectedMetersEndDate (): string {
      const endDate = moment(this.meterSelectionDates[0]).isAfter(moment(this.meterSelectionDates[1]))
        ? moment(this.meterSelectionDates[0])
        : moment(this.meterSelectionDates[1])

      if (endDate.isBefore(moment({ year: moment().year(), month: moment().month(), date: moment().day() }))) {
        return endDate.endOf('day').toISOString()
      } else {
        return moment().toISOString(true)
      }
    }
  },

  async mounted () {
    await this.$store.dispatch('energy_management/fetchMeters')
    if (this.meters.length > 0) {
      this.selectedMeters = [this.meters[0]]
      this.selectMeter({ item: this.meters[0], value: true })
      this.initialMeters = [{ text: this.meters[0].identifier, value: this.meters[0].originalName }]
      this.metersSelected([this.meters[0].originalName])
    }
  },

  methods: {
    toggleConsumption (showConsumption: boolean) {
      this.$store.dispatch('energy_management/toggleConsumptionSelection', showConsumption)
    },

    metersSelected (selectedMeters: string[]) {
      this.currentSelectedTimeSeries = selectedMeters
      this.timeSeriesMap.forEach((value: Map<string, any>, key: string) => {
        if (!selectedMeters.includes(key)) {
          this.timeSeriesMap.delete(key)
        }
      })
      this.metersTimeSeries = this.currentSelectedTimeSeries.length === 1 ? this.currentSelectedTimeSeries[0] : this.currentSelectedTimeSeries.join(',')
      this.providaMetersData()
    },

    initiateDownload (url: string) {
      window.open(url, '_blank')
    },

    async pollReportStatus () {
      this.pendingPdfGeneration = true
      this.pollReportInterval = setInterval(async () => {
        const documentId = this.$store.state.energy_management.pdfReportId as string|null
        if (documentId) {
          const headerInit: HeadersInit = {
            Authorization: `Bearer ${window.secrets.PDF_TOKEN}`
          }
          const headers: Headers = new Headers(headerInit)
          const requestDocumentById: Request = new Request(new URL(`https://api.pdfmonkey.io/api/v1/document_cards/${documentId}`).toString(), {
            method: 'GET',
            headers: headers,
            mode: 'cors'
          })
          const responseDocumentById: Response = await fetch(requestDocumentById)
          const jsonDocumentById: any = await responseDocumentById.json()
          if (jsonDocumentById.document_card.status === 'success') {
            const download_url = jsonDocumentById.document_card.download_url as string
            this.$store.commit('energy_management/SET_PDF_REPORT', jsonDocumentById.document_card.download_url)
            clearInterval(this.pollReportInterval)
            this.initiateDownload(download_url)
            this.pendingPdfGeneration = false
          }
        }
      }, 1000)
    },

    async requestReport () {
      if (this.monthlyReport === true) {
        // if selectedReportMonth is undefined then set the monthlyReportSelectionError to true
        if (this.selectedReportMonth === undefined) {
          this.monthlyReportSelectionError = true
          return
        }

        const shortPayload = {
          short: true,
          selectedReportMonth: this.selectedReportMonth
        }

        this.$store.dispatch('energy_management/fetchPDFReport', shortPayload)
        this.pollReportStatus()
        return
      }

      const yearlyComparisonData: YearlyComparisonObject = {}
      this.timeSeriesMap.forEach((value: Map<string, any>, dataPointId: string) => {
        // yearlyComparisonData[dataPointId] = Object.fromEntries(value)
        const meterName: string|undefined = this.meters.find((item: TFlattenedMeterComponent) => item.originalName === dataPointId)?.identifier
        value.forEach((timeseries: any, year: string) => {
          const name = meterName ?? dataPointId
          if (yearlyComparisonData[name] === undefined) {
            yearlyComparisonData[name] = {}
          }
          yearlyComparisonData[name][year] = this.handleTimeSeries(timeseries as any)
        })
      })

      const payload: FetchPDFReportPayload = {
        benchmark: {
          heat: {
            plotBands: ((this.$refs.mediaContent as any)?.$refs.heatBulletGraph as any)?.chartOptions.yAxis.plotBands ?? undefined,
            min: ((this.$refs.mediaContent as any)?.$refs.heatBulletGraph as any)?.chartOptions.yAxis.min ?? undefined,
            max: ((this.$refs.mediaContent as any)?.$refs.heatBulletGraph as any)?.chartOptions.yAxis.max ?? undefined
          },
          electricity: {
            plotBands: ((this.$refs.mediaContent as any)?.$refs.electricityBulletGraph as any)?.chartOptions.yAxis.plotBands ?? undefined,
            min: ((this.$refs.mediaContent as any)?.$refs.electricityBulletGraph as any)?.chartOptions.yAxis.min ?? undefined,
            max: ((this.$refs.mediaContent as any)?.$refs.electricityBulletGraph as any)?.chartOptions.yAxis.max ?? undefined
          }
        },
        raster: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          electricity: (this.$refs.rasterPlots as any).electricitySeries.length > 0 ? {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            series: (this.$refs.rasterPlots as any).electricitySeries,
            min: ((this.$refs.rasterPlots as any)?.$refs.electricityRasterPlot as any).min,
            max: ((this.$refs.rasterPlots as any)?.$refs.electricityRasterPlot as any).max
          } : undefined,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          heat: (this.$refs.rasterPlots as any).heatSeries.length > 0 ? {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            series: (this.$refs.rasterPlots as any).heatSeries,
            min: ((this.$refs.rasterPlots as any)?.$refs.heatRasterPlot as any).min,
            max: ((this.$refs.rasterPlots as any)?.$refs.heatRasterPlot as any).max
          } : undefined
        },
        pieChart: {
          electricity: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            percentage: (this.$refs.mediaContent as any).electricityRelative ? parseInt((this.$refs.mediaContent as any).electricityRelative) : undefined
          },
          heat: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            percentage: (this.$refs.mediaContent as any).heatRelative ? parseInt((this.$refs.mediaContent as any).heatRelative) : undefined
          },
          date_range: (this.$refs.mediaContent as any).range
        },
        metersLinePlotSeries: this.singleSelectMeterSeries.length > 0 ? this.singleSelectMeterSeries : undefined,
        metersLinePlot: {
          date_range: this.meterSelectionDates.length > 0 ? (this.meterSelectionDates.length > 1 ? `${moment(this.meterSelectionDates[1]).format('DD.MM YYYY')} - ${moment(this.meterSelectionDates[0]).format('DD.MM YYYY')}` : moment(this.meterSelectionDates[0]).format('DD.MM YYYY')) : undefined
        },
        metersYearComparisonSeries: this.timeSeriesMap.size === 0 ? undefined : yearlyComparisonData,
        metersYearComparison: {
          date_range: this.yearsToTimeSeries.length > 0 ? (this.yearsToTimeSeries.length > 1 ? this.yearsToTimeSeries.join(',') : this.yearsToTimeSeries[0]) : undefined
        },
        annual: {
          heatSeries: (this.$refs.annualDurationLines as any).heatSeries.length > 0 && (this.$refs.annualDurationLines as any).heatSeries[0].data.length > 0 ? (this.$refs.annualDurationLines as any).heatSeries : undefined,
          electricitySeries: (this.$refs.annualDurationLines as any).electricitySeries.length > 0 && (this.$refs.annualDurationLines as any).electricitySeries[0].data.length > 0 ? (this.$refs.annualDurationLines as any).electricitySeries : undefined
        }
      }

      this.$store.dispatch('energy_management/fetchPDFReport', payload)
      this.pollReportStatus()
    },

    yearsSelected (yearsValues: string[]) {
      this.yearsToTimeSeries = yearsValues.sort()

      if (this.yearsToTimeSeries.length > 0 && this.metersTimeSeries.length > 0) {
        this.providaMetersData()
      } else {
        this.removeMetersUnselected()
      }
    },

    /**
     * @param {string} datapoint ID
     * @returns {string} meter name or datapoint ID if meter was not found
     */
    getMeterNameByDataPointId (dataPointId: string): string {
      return this.meters.find((item: TFlattenedMeterComponent) => item.originalName === dataPointId)?.identifier ?? dataPointId
    },

    checkAndSetSelectedTimeSeries (
      timeSeriesResponse: any,
      dataPointID: string,
      year: string
    ) {
      const meterName: string = this.getMeterNameByDataPointId(dataPointID)

      let isInArray = false
      for (let j = 0; j < this.selectedTimeSeries.length; j++) {
        if (meterName === this.selectedTimeSeries[j].title && year === this.selectedTimeSeries[j].year) {
          isInArray = true
          break
        }
      }

      if (!isInArray) {
        // group given timeseries by datapoint-id and year in a map
        if (this.timeSeriesMap.get(dataPointID)) {
          this.timeSeriesMap.get(dataPointID)!.set(year, timeSeriesResponse)
        } else {
          const timeSeriesMap = new Map<string, any>()
          timeSeriesMap.set(year, timeSeriesResponse)
          this.timeSeriesMap.set(dataPointID, timeSeriesMap)
        }

        this.selectedTimeSeries.push({
          name: `${meterName} - ${year}`,
          title: meterName,
          data: this.handleTimeSeries(timeSeriesResponse),
          year
        })
      }
    },

    checkEmptyResponse (timeSeriesResponse: any, year: string) {
      if (timeSeriesResponse && timeSeriesResponse.length === 0) {
        this.$notify({
          text: `${i18n.t('operations.datapoints.datapointsNotFound')} ${year}`,
          group: 'requests',
          duration: 5000,
          type: 'error'
        })
        return false
      }
      return true
    },

    handleTimeSeries (timeSeriesResponse: any) {
      if (!timeSeriesResponse || timeSeriesResponse.length === 0) {
        return []
      }
      const result = timeSeriesResponse
        .map((obs: any) => {
          return {
            x: moment(obs[0]).month(),
            y: obs[1] !== null ? parseFloat(obs[1].toFixed(0)) : null
          }
        })

      return result
    },

    /**
     * @param {string} meter name
     * @returns {string} datapoint ID or null if meter was not found
     */
    getDataPointIdByMeterName (meterName: string): string|null {
      return this.meters.find((item: TFlattenedMeterComponent) => item.identifier === meterName)?.originalName ?? null
    },

    removeMetersUnselected () {
      const lengthTimeSeries = this.currentSelectedTimeSeries.length
      const years = this.yearsToTimeSeries.length > 0 ? this.yearsToTimeSeries : this.initialYear

      if ((lengthTimeSeries * years.length) !== this.selectedTimeSeries.length) {
        if (lengthTimeSeries === 0 || years.length === 0) {
          this.selectedTimeSeries = []
          this.timeSeriesMap = new Map()
        }

        for (let pos = 0; pos < this.selectedTimeSeries.length; pos++) {
          const meterName = this.selectedTimeSeries[pos].title
          const dataPointId = this.getDataPointIdByMeterName(meterName)
          if (dataPointId === null) {
            this.selectedTimeSeries.splice(pos, 1)
            break
          }
          const hasTimeSeries = this.currentSelectedTimeSeries.includes(dataPointId)
          const hasYear = years.includes(this.selectedTimeSeries[pos].year)

          if (
            !hasTimeSeries || !hasYear
          ) {
            this.selectedTimeSeries.splice(pos, 1)
            pos--

            if (this.timeSeriesMap.has(dataPointId)) {
              if (this.timeSeriesMap.get(dataPointId)!.get(dataPointId)) {
                this.timeSeriesMap.get(dataPointId)!.delete(dataPointId)

                if (this.timeSeriesMap.get(dataPointId)!.size === 0) {
                  this.timeSeriesMap.delete(dataPointId)
                }
              }
            }
          }
        }
      }
    },

    async providaMetersData () {
      const token: string = this.$store.getters['auth/oidcAccessToken']
      const project_id: string = this.$store.getters['project/getProjectID']
      const yearsList: string[] = this.yearsToTimeSeries.length === 0 ? this.initialYear : this.yearsToTimeSeries

      try {
        for (let y = 0; y < yearsList.length; y++) {
          const datapointsTimeseriesDataForAllMonths = new Map()
          // if the year is the same as the current year, set the month to the current month
          const maximumMonth = parseInt(yearsList[y]) === moment().year() ? moment().month() : 11
          const monthlyRequests = []
          for (let month = 0; month <= maximumMonth; month++) {
            const currentMonthDateStart = moment({ year: parseInt(yearsList[y]), month }).startOf('month')
            const currentMonthDateEnd = moment({ year: parseInt(yearsList[y]), month }).endOf('month')

            let endRequestDate = null
            if (currentMonthDateEnd.isAfter(moment())) {
              endRequestDate = moment()
            } else {
              endRequestDate = currentMonthDateEnd
            }

            monthlyRequests.push(
              Project.getTimeseries({
                token,
                id: project_id,
                params: {
                  dataPointIDs: this.metersTimeSeries,
                  short: true,
                  start: currentMonthDateStart.toISOString(true),
                  end: endRequestDate.toISOString(true),
                  samplerate: '1d',
                  closed_interval: false,
                  interpolation: 'null'
                }
              })
            )
          }

          // await all monthly requests
          const monthlyRequestsData = await Promise.all(monthlyRequests)

          // for each month, calculate the consumption value
          monthlyRequestsData.forEach((timeseriesResponse, monthIndex) => {
            const timeseriesData = timeseriesResponse.data
            for (const [dataPointID, timeseries] of Object.entries(timeseriesData)) {
              const consumptionData: number|null = computeTotalConsumptionValue(timeseries as [string, any])
              if (datapointsTimeseriesDataForAllMonths.has(dataPointID)) {
                datapointsTimeseriesDataForAllMonths.set(
                  dataPointID,
                  [
                    ...datapointsTimeseriesDataForAllMonths.get(dataPointID),
                    [
                      (timeseries as [string, any]).length > 0 ? (timeseries as [string, any])[(timeseries as [string, any]).length - 1][0] : null,
                      consumptionData
                    ]
                  ]
                )
              } else {
                datapointsTimeseriesDataForAllMonths.set(dataPointID, [[
                  (timeseries as [string, any]).length > 0 ? (timeseries as [string, any])[(timeseries as [string, any]).length - 1][0] : null,
                  consumptionData
                ]])
              }
            }
          })

          datapointsTimeseriesDataForAllMonths.forEach((timeseriesValue: [{ x: number; y: number | null }], datapoint: string) => {
            this.checkAndSetSelectedTimeSeries(
              timeseriesValue,
              datapoint,
              yearsList[y]
            )
          })
        }
        this.removeMetersUnselected()
      } catch (error) {
        this.$notify({
          text: i18n.t('operations.datapoints.notBeFetched') as string,
          group: 'requests',
          duration: 5000,
          type: 'error'
        })
      }
    },

    updateDaterangeSelection () {
      if (this.selectedMeters.length > 0) {
        this.selectAllVisibleMeters({ items: this.selectedMeters, value: true })
      }
      this.multiselectMeterDatepickerMenu = false
    },

    async selectMeter (selection: { item: TFlattenedMeterComponent; value: boolean }): Promise<void> {
      const { item, value } = selection
      const token: string = this.$store.getters['auth/oidcAccessToken']
      const project_id: string = this.$store.getters['project/getProjectID']

      if (value) {
        // set the loading state to true
        this.loadingSingleSelectMeterSeries = true
        try {
          // Make timeseries request for selected rows' datapoint
          const timeseriesResponse: AxiosResponse<TObservationShort[]> = await Datapoint.getTimeseries({
            token,
            params: {
              dataPointID: item.originalName,
              project_id,
              short: true,
              start: this.selectedMetersStartDate,
              end: this.selectedMetersEndDate,
              samplerate: 'auto',
              closed_interval: true
            }
          })

          // format result data to applicable format
          const data: [number, number][] = timeseriesResponse.data.map((observation: [string, number]) => {
            return [
              moment(observation[0]).valueOf(),
              observation[1] !== null ? parseFloat((observation[1]).toFixed(2)) : null
            ]
          }) as [number, number][]
          /**
          * pushes data if value == true
          * which means that if the row is selected or not
          */
          this.singleSelectMeterSeries.push({
            identifier: item.identifier,
            name: `${this.$t('energy_management.meters.chart.series_title.meter')}: ${item.identifier}: ${item.name}`,
            step: 'left',
            data
          })
        } catch (error) {
          this.$notify({
            text: this.$t('energy_management.meters.chart.timeseries_error') as string,
            group: 'requests',
            duration: 6000,
            type: 'error'
          })
        } finally {
          this.loadingSingleSelectMeterSeries = false
        }
      } else {
        // find index of selected row
        const foundSeriesIndex: number = this.singleSelectMeterSeries.findIndex((series: { identifier: string; name: string; step: string; data: [number, number][] }) => {
          return series.identifier === item.identifier
        })
        if (foundSeriesIndex > -1) {
          this.singleSelectMeterSeries.splice(foundSeriesIndex, 1)
        }
      }
    },

    async selectAllVisibleMeters (selection: { items: TFlattenedMeterComponent[]; value: boolean }) {
      const { items, value } = selection
      const token: string = this.$store.getters['auth/oidcAccessToken']
      const project_id: string = this.$store.getters['project/getProjectID']

      if (value) {
        this.loadingSingleSelectMeterSeries = true
        try {
          const timeseriesResponse: AxiosResponse<TObservationShort[]> = await Project.getTimeseries({
            token,
            id: project_id,
            params: {
              dataPointIDs: items.map((item: TFlattenedMeterComponent) => item.originalName).join(','),
              short: true,
              start: this.selectedMetersStartDate,
              end: this.selectedMetersEndDate,
              samplerate: 'auto',
              closed_interval: true
            }
          })

          const newDataSeries: Array<{ identifier: string; name: string; step: string; data: [number, number][] }> = []

          Object.keys(timeseriesResponse.data).forEach((datapoint: string) => {
            const data: [number, number][] = get(timeseriesResponse, `data.${datapoint}`, []).map((observation: [string, number]) => {
              return [
                moment(observation[0]).valueOf(),
                observation[1] !== null ? parseFloat((observation[1]).toFixed(0)) : null
              ]
            }) as [number, number][]
            const foundItem: TFlattenedMeterComponent|undefined = items.find((item_: TFlattenedMeterComponent) => item_.originalName === datapoint)
            newDataSeries.push({
              identifier: foundItem ? foundItem.identifier : datapoint,
              name: foundItem ? `${this.$t('energy_management.meters.chart.series_title.meter')}: ${foundItem.identifier}: ${foundItem.name}` : datapoint,
              step: 'left',
              data
            })
          })

          this.singleSelectMeterSeries = newDataSeries
        } catch (error) {
          this.$notify({
            text: this.$t('energy_management.meters.chart.timeseries_error') as string,
            group: 'requests',
            duration: 6000,
            type: 'error'
          })
        } finally {
          this.loadingSingleSelectMeterSeries = false
        }
      } else {
        this.singleSelectMeterSeries = []
      }
    }
  }
})
