import { getMeterPinUnitByAlphanumericID, meterCounterPinIDs, meterHasOneEnergyManagementPin } from '@/utils/helpers/meters'
import {
  GetterTree
} from 'vuex'
import get from 'lodash.get'
import { TRootState } from '../types'
import { TEnergyManagementState, TFlattenedMeterComponent } from './types'
import { getAPILanguageResponseKey } from '@/utils/helpers/translations'
import { computeTotalConsumptionValue, getLastValue } from '@/utils/helpers/timeseries'
import { getAttrbiuteValueByAlphanumericId } from '@/utils/helpers/attributes'
import i18n from '@/plugins/i18n'
import { AssignedPin, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'

function getTypeName (alphanumeric_id: string|null): string {
  if (alphanumeric_id === null) {
    return i18n.t('energy_management.meters.table.unknown') as string
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (alphanumeric_id!.includes('ELM')) {
    return i18n.t('energy_management.meters.table.ELM') as string
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } else if (alphanumeric_id!.includes('GASM')) {
    return i18n.t('energy_management.meters.table.GASM') as string
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } else if (alphanumeric_id!.includes('WSM')) {
    return i18n.t('energy_management.meters.table.WSM') as string
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } else if (alphanumeric_id!.includes('HM')) {
    return i18n.t('energy_management.meters.table.HM') as string
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } else if (alphanumeric_id!.includes('CM')) {
    return i18n.t('energy_management.meters.table.CM') as string
  } else {
    return i18n.t('energy_management.meters.table.unknown') as string
  }
}

export default {
  isLoadingMeters: (state: TEnergyManagementState): boolean => state.loadingMeters,
  isLoadingMetersLatestTimeseries: (state: TEnergyManagementState): boolean => state.loadingMetersLatestTimeseries,
  showConsumption: (state: TEnergyManagementState): boolean => state.showConsumption,
  getMeters: (state: TEnergyManagementState): TFlattenedMeterComponent[] => {
    const showConsumption: boolean = state.showConsumption
    const requiredMeters: TFlattenedMeterComponent[] = state.meters
      .filter(
        (meter: ComponentInProjectWithContext) => meterHasOneEnergyManagementPin(meter)
      )
      .map((meter: ComponentInProjectWithContext) => {
        const meterName = get(meter, 'nameEN', i18n.t('energy_management.meters.no_name_specified') as string)
        const meterNumberAttribute = getAttrbiuteValueByAlphanumericId(meter.attributes!, '+M_NUM') ?? i18n.t('energy_management.meters.no_number_specified') as string
        const meterMedia: string = get(meter, `component.${getAPILanguageResponseKey()}`, 'No media specified') // Media
        const meterPins = meter.pins!
          .filter((pin: AssignedPin) => meterCounterPinIDs.includes(pin.alphanumeric_id!))
          .map((pin: AssignedPin) => {
            const monthlyObservations = {
              jan: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.0.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.0.${get(pin, 'dataPointID', undefined)}`, [])),
              feb: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.1.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.1.${get(pin, 'dataPointID', undefined)}`, [])),
              mar: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.2.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.2.${get(pin, 'dataPointID', undefined)}`, [])),
              apr: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.3.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.3.${get(pin, 'dataPointID', undefined)}`, [])),
              may: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.4.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.4.${get(pin, 'dataPointID', undefined)}`, [])),
              jun: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.5.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.5.${get(pin, 'dataPointID', undefined)}`, [])),
              jul: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.6.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.6.${get(pin, 'dataPointID', undefined)}`, [])),
              aug: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.7.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.7.${get(pin, 'dataPointID', undefined)}`, [])),
              sep: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.8.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.8.${get(pin, 'dataPointID', undefined)}`, [])),
              okt: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.9.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.9.${get(pin, 'dataPointID', undefined)}`, [])),
              nov: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.10.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.10.${get(pin, 'dataPointID', undefined)}`, [])),
              dec: showConsumption
                ? computeTotalConsumptionValue(get(state, `timeseries.11.${get(pin, 'dataPointID', undefined)}`, []))
                : getLastValue(get(state, `timeseries.11.${get(pin, 'dataPointID', undefined)}`, []))
            }

            const type = getTypeName(get(pin, 'alphanumeric_id', null))

            return {
              id: get(pin, 'dataPointID', 'No datapoint ID specified'), // Identifier
              alphanumeric_id: get(pin, 'alphanumeric_id', 'No alphanumeric ID specified'), // Alphanumeric ID
              type,
              name: meterName, // Name
              originalName: get(pin, 'dataPointID', 'No datapoint ID specified'), // Datapoint ID
              identifier: meterNumberAttribute, // Counter number
              media: meterMedia, // Media
              unit: getMeterPinUnitByAlphanumericID(get(pin, 'alphanumeric_id', null)),
              selectable: true,
              meter_id: meter.id!,
              monthly: monthlyObservations
            }
          })
        return meterPins
      })
      .flat()

    return requiredMeters
  }
} as GetterTree<TEnergyManagementState, TRootState>
