import Vue from 'vue'
import {
  ActionTree
} from 'vuex'
import moment from 'moment'
import { TRootState } from '../types'
import { EnergyManagementPDFPayload, FetchPDFReportPayload, TEnergyManagementState, TFlattenedMeterComponent } from './types'
import { TComponentInProject, TTimeseriesShort, TProjectWithContext } from '@/services/aedifion/resources/project/responseTypes'
import { ComponentInProject, ComponentInProjectWithContext, ComponentsInProject, aedifionApi } from '@aedifion.io/aedifion-api'

function toIntOrNull (value: number|null): number|null {
  if (value === null) {
    return null
  }

  return parseInt(value.toFixed(0))
}

export default {
  toggleConsumptionSelection: ({ commit, dispatch }, showConsumption: boolean) => {
    commit('TOGGLE_CONSUMPTION', showConsumption)
    dispatch('fetchLatestMeterTimeseries')
  },
  fetchMeters: async ({ commit, rootGetters, dispatch }) => {
    commit('SET_LOADING_METERS', true)
    const project_id = rootGetters['project/getProjectID']
    if (project_id === null) throw new Error('No project selected.')

    let meters: ComponentInProject[] = []
    let metersWithContext: ComponentInProjectWithContext[]

    try {
      const initialMetersRequestData: ComponentsInProject = await aedifionApi.Project.getProjectComponents(
        project_id,
        1,
        100,
        undefined,
        'alphanumeric_id=WSM;alphanumeric_id=HM;alphanumeric_id=GASM;alphanumeric_id=ELM;alphanumeric_id=CM'
      )
      const pages = initialMetersRequestData.meta!.total_pages
      meters = [...initialMetersRequestData.items as ComponentInProject[]]
      if (pages > 1) {
        const otherMeterRequests = await Promise.all([...Array(pages - 1)].map((_, index) => {
          return aedifionApi.Project.getProjectComponents(
            project_id,
            index + 2,
            100,
            undefined,
            'alphanumeric_id=WSM;alphanumeric_id=HM;alphanumeric_id=GASM;alphanumeric_id=ELM;alphanumeric_id=CM'
          )
        }))
        const otherMeters: ComponentInProject[] = otherMeterRequests.map((res: ComponentsInProject) => res.items as ComponentInProject[]).flat()
        meters = [...meters, ...otherMeters]
      }
    } catch (error) {
      Vue.notify({
        text: 'Meters could not be fetched' as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    }

    if (meters.length === 0) {
      commit('SET_LOADING_METERS', false)
      return
    }

    try {
      const metersInProjectWithContextResponse: Array<ComponentInProjectWithContext> = await Promise.all(meters.map((component: TComponentInProject) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return aedifionApi.Project.getProjectComponent(project_id, component.id!)
      }))
      metersWithContext = metersInProjectWithContextResponse.map((res: ComponentInProjectWithContext) => res)
      commit('SET_METERS', metersWithContext)
      dispatch('fetchLatestMeterTimeseries')
    } catch (error) {
      Vue.notify({
        text: 'Meter information could not be fetched' as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_METERS', false)
    }
  },
  fetchLatestMeterTimeseries: async ({ commit, rootGetters }) => {
    commit('SET_LOADING_METERS_LATEST_TIMESERIES', true)
    const project_id = rootGetters['project/getProjectID']
    if (project_id === null) throw new Error('No project selected.')

    const meterDatapoints: string[] = rootGetters['energy_management/getMeters'].map((meter: TFlattenedMeterComponent) => meter.id)

    if (meterDatapoints.length === 0) {
      commit('SET_LOADING_METERS_LATEST_TIMESERIES', false)
      return
    }

    try {
      const currentYear: number = moment().year()
      const timeseriesForEachMonth: Array<TTimeseriesShort> = await Promise.all([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
        return aedifionApi.Project.getProjectTimeseries(
          project_id,
          meterDatapoints,
          moment({ month: month - 1, year: currentYear }).startOf('month').toDate(),
          moment({ month: month - 1, year: currentYear }).endOf('month').toDate(),
          1,
          '1d',
          'null',
          undefined,
          true,
          false
        ) as unknown as Promise<TTimeseriesShort>
      }))

      commit('SET_METERS_TIMESERIES', timeseriesForEachMonth)
    } catch (error) {
      Vue.notify({
        text: 'Could not fetch timeseries information' as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_METERS_LATEST_TIMESERIES', false)
    }
  },

  fetchPDFReport: async ({ commit, rootGetters, rootState }, payload: FetchPDFReportPayload) => {
    commit('SET_LOADING_PDF_REPORT', true)

    try {
      const reportDate = new Date()
      const reportDay = reportDate.getDate()
      const reportMonth = reportDate.getMonth() + 1
      const reportYear = reportDate.getFullYear()
      // create date string in german format
      const reportDateString = `${reportDay}.${reportMonth}.${reportYear}`

      let monthName
      if (payload.selectedReportMonth) {
        monthName = moment({ month: payload.selectedReportMonth }).locale('de').format('MMMM YYYY')
      }

      const document = {
        document: {
          document_template_id: window.configuration.PDF_TEMPLATE_ID,
          meta: {
            _filename: 'rom-bc-energy-report.pdf'
          },
          payload: {
            project_name: (rootGetters['project/getProject'] as TProjectWithContext|null)?.project?.name as string|undefined,
            project_address: (rootGetters['project/getProject'] as TProjectWithContext|null)?.project?.address as string|undefined,
            gross_floor_area: rootGetters['project/getProjectsGrossFloorArea'] as number|null ?? undefined,
            project_image: rootGetters['project/getProjectAvatar'] as string|undefined,
            report_date: reportDateString,
            monthName,
            selectedMonth: payload.selectedReportMonth ?? 11,
            benchmark: payload.benchmark ? {
              heat: {
                setValue: toIntOrNull(rootGetters['energy_consumption/getTargetHeatValue']),
                isValue: toIntOrNull(rootGetters['energy_consumption/getHeatConsumptionBenchmark']),
                projection: toIntOrNull(rootState.energy_consumption.benchmark.heatProjection),
                color: rootGetters['energy_consumption/getHeatConsumptionColor'],
                plotBands: payload.benchmark?.heat.plotBands,
                min: payload.benchmark?.heat.min,
                max: payload.benchmark?.heat.max
              },
              electricity: {
                setValue: toIntOrNull(rootGetters['energy_consumption/getTargetElectricityValue']),
                isValue: toIntOrNull(rootGetters['energy_consumption/getElectricityConsumptionBenchmark']),
                projection: toIntOrNull(rootState.energy_consumption.benchmark.electricityProjection),
                color: rootGetters['energy_consumption/getElectricityConsumptionColor'],
                plotBands: payload.benchmark?.electricity.plotBands,
                min: payload.benchmark?.electricity.min,
                max: payload.benchmark?.electricity.max
              }
            } : undefined,
            raster: payload.raster ? { ...payload.raster } : undefined,
            pieChart: payload.pieChart ? {
              date_range: payload.pieChart.date_range ? payload.pieChart.date_range : moment().format('YYYY'),
              heat: {
                percentage: payload.pieChart?.heat?.percentage,
                value: rootGetters['energy_consumption/getHeatConsumption'] ?? undefined
              },
              electricity: {
                percentage: payload.pieChart?.electricity?.percentage,
                value: rootGetters['energy_consumption/getElectricityConsumption'] ?? undefined
              }
            } : undefined,
            meters: (rootGetters['energy_management/getMeters'] ?? []).map((meter: TFlattenedMeterComponent) => {
              let monthlyData = {}
              for (let i = 0; i < Object.values(meter.monthly).length; i++) {
                if (payload.selectedReportMonth !== undefined) {
                  if (i <= payload.selectedReportMonth) {
                    monthlyData = { ...monthlyData, [Object.keys(meter.monthly)[i]]: Object.values(meter.monthly)[i] }
                  }
                } else {
                  monthlyData = meter.monthly
                }
              }
              return {
                ...monthlyData,
                number: meter.identifier,
                name: meter.media
              }
            }),
            metersLinePlot: {
              date_range: payload.metersLinePlot?.date_range,
              series: payload.metersLinePlotSeries ?? undefined
            },
            metersYearComparison: {
              date_range: payload.metersYearComparison?.date_range,
              series: payload.metersYearComparisonSeries ?? undefined
            },
            annual: {
              heat: payload.annual?.heatSeries ? {
                series: payload.annual.heatSeries
              } : undefined,
              electricity: payload.annual?.electricitySeries ? {
                series: payload.annual.electricitySeries
              } : undefined
            }
          } as EnergyManagementPDFPayload,
          status: 'pending'
        }
      }

      const documentGenerationRequestHeaders: Headers = new Headers({
        Authorization: `Bearer ${window.secrets.PDF_TOKEN}`,
        'Content-Type': 'application/json'
      })
      const requestDocumentGeneration: Request = new Request(new URL('https://api.pdfmonkey.io/api/v1/documents').toString(), {
        method: 'POST',
        headers: documentGenerationRequestHeaders,
        mode: 'cors',
        body: JSON.stringify(document)
      })
      const responseDocumentGeneration: Response = await fetch(requestDocumentGeneration)

      // get the generated document id
      const documentId: string = await responseDocumentGeneration.json().then((response: any) => response.document.id)

      commit('SET_PDF_REPORT_ID', documentId)
    } catch (error) {
      Vue.notify({
        text: 'Could not generate PDF report' as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_PDF_REPORT', false)
    }
  }
} as ActionTree<TEnergyManagementState, TRootState>
